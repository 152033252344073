<template>
  <div>
        <div class="header" style="border-bottom:1px solid #f5f5f5">
             <ul class="left_menu">
                <div class="item"> 
                    <li class=" a" @click="go" style="    cursor: pointer"> <i class="el-icon-back"></i> 返回</li>
                 
                </div>
               
               
             </ul>
        </div>
             <!-- <div class="search" style="padding:7.5px 40px; display:flex;font-size:14px;justify-content: space-between;align-items: center;">
            <div style=" display:flex;">
                <p style="font-size: 22px;
font-family: PingFangSC-Semibold, PingFang SC;
font-weight: 600;
color: #000000;
line-height: 30px;">消息中心</p>
                 <div class="demo-input-suffix" style="margin-right:15px">
              <span style="margin-right:5px">关键字</span>  
                <el-input style="width:150px;height:30px"
                    placeholder="请输入"
               
                    v-model="input1">
                </el-input>
            </div>
             <div class="block" style="margin-right:15px">
                <span class="demonstration" style="margin-right:5px">发布时间</span>
                <el-date-picker
                style="width:150px;"
                v-model="value1"
                type="date"
                placeholder="选择日期">
                </el-date-picker>
            </div>
            <div>
                <span class="demonstration" style="margin-right:5px;">公告类型</span>
                    <el-select v-model="select" slot="prepend" placeholder="请选择" style="width:150px">
                    <el-option label="普通公告" value="1"></el-option>
                    <el-option label="重要公告" value="2"></el-option>
                  
                 </el-select>
             </div>
            </div>
            <div style="display:flex" class="l">
                <p style="width: 80px;
height: 35px;line-height:35px;cursor: pointer;
border: 1px solid #DFE0E8;margin-right:20px;text-align:center" @click="ck1">重置</p> <p style="width: 80px;
height: 35px;line-height:35px;text-align:center;cursor: pointer;
border: 1px solid #DFE0E8;" @click="ck2">查询</p>
            </div>
        </div> -->
        <div class="content" style="padding:10px 40px;background:#f5f5f5">
                    
              <el-table
                    :border="true"
                    :data="tableData"
                    
                :header-cell-style="{background:'#FAFAFA'}"
                    style="width: 100%;text-align:center">
                    
                    <el-table-column
                    :show-overflow-tooltip="true" 
                
                    prop="name"
                    label="城市"
                  >
                    </el-table-column>
                    <el-table-column
                        prop="dls_total" 
                    label="代理商数量"
                        :show-overflow-tooltip="true" 
                   >
                    </el-table-column>

                    <el-table-column
                    label="累计会员数"
                    prop="total" 
                    
                   >
                    
                    </el-table-column>
                    

                    
                    <el-table-column
                        prop="this_month" 
                    label="本月新增会员数" 
                        :show-overflow-tooltip="true" 
                    width="300">
                
                    </el-table-column>

                     
          
                </el-table>
        

    
        </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
         activeName: 'first',
         tableData:'',
      
        }
    },
    methods:{
        go(){
              this.$router.go(-1)
        }
    },
    mounted(){

               this.$axios.get("/s/backend/index/top_six_customer_detail", {
    　  　params: { 'accessToken':localStorage.getItem('token'),
        'page':'1',
        'limit':'1',
        'id':localStorage.getItem('xq')
    }
    })

        .then(res=>{
            
        this.tableData = res.data.result
        })
        .catch(function(){
           
        })
    }
}
</script>

<style scoped>
     .header{
         min-height: 57px;
  font-size: 16px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
    }
    .header .left_menu{
        display: flex;   width: 100%;  padding:0 40px;
        justify-content: space-between;
    }
    .header .left_menu .item{
        display: flex;
    }
        .header .left_menu .item .a{
            margin-right: 60px;
        }
           .header .left_menu .item li a{
               text-decoration: none;color: #000;
           }

       .header .active{
            color: #0034FF;
            position:relative;
       
        }
            .header .active::before{
                      content: "";
   
         left: 0;
    position: absolute;
    bottom:-80%;
    height: 2px;
    width:30px;
    background: #0034FF;
            }
    .header .active a{
          color: #0034FF !important;
    }
    /deep/ .el-tabs--card>.el-tabs__header .el-tabs__item{
    border-bottom:1px solid #E4E7ED;}
/deep/ .el-tabs__item.is-active{
    border:1px solid #409EFF;
    background: #409EFF;
    color: #fff;
    border-left:1px solid #409EFF !important;
      border-bottom:1px solid #409EFF !important;
}
</style>





